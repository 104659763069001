import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { darken } from 'polished';
import ReactPlayer from 'react-player';

import { media } from '../MediaQueries';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import { HireMe, LinkButton } from '../components/Button.js';
import HireMePopup from '../components/HireMePopup.js';
import Colors from '../Colors';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Swagger from '../images/sms-swagger.png';
import CountCommits from '../images/demo-count-commits.gif';
import Yard from '../images/yard.png';
import Myynti from '../images/myynti.png';
import EY from '../images/ey.png';
import thesis from '../images/thesis.png';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${Colors.darkest};
  }
`;

const Block = styled.div`
  &:nth-child(odd) {
    border: solid 1px ${darken(0.1, Colors.light)};
    background-color: ${Colors.light};
  }
`;

const BlockContent = styled(Content)`
  ${Mixins.block}
  padding: 100px 40px;
  ${media.tablet`
    flex-direction: column;
    align-items: baseline;
  `};
  ${media.phone`
    padding: 40px 10px;
  `};
  ${t.P} {
    margin-top: 10px;
  }
  ${t.H2} {
    margin-top: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const DivWrapper = styled.div`
  padding: 80px 30px;
  ${media.tablet`padding: 50px 0;`}
  &:first-child {
    ${media.tablet`
      margin-bottom: 40px;
  `};
  }
`;

const ItemImage = styled.img`
  max-width: 85%;
  position: relative;
  ${media.tablet`max-width: none;`}
`;

const ItemVid = styled.div`
  &video {
    max-width: 85%;
    position: relative;
    ${media.tablet`max-width: none;`}
  }
`;

const HomepageWrapper = styled.div`
  ${Mixins.wrapper}
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }
  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underlined;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`;

const WorkWithMe = styled.div`
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(57, 55, 55, 0.08);
  background-color: #ffffff;
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`;

class Homepage extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <HomepageWrapper>
        <Layout theme="white" bigFooter openContactPopup={this.openContactPopup}>
          <AboveFold>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Daniel Stafford" className="avatar" />
            <t.H1 primary align="center">
              Daniel Stafford
            </t.H1>
            <t.LargeP align="center" max70>
              An outgoing and detail oriented <b>data wrangler</b> and <b>full-stack </b> developer. Additional
              experience as a clinically trained <b>registered nurse</b> and former <b>international affairs analyst</b>
            </t.LargeP>
            <t.LargeP align="center" max70>
              Skills in <b>Python, QGIS, and R</b> on the data side of things.
            </t.LargeP>
            <t.LargeP align="center" max70>
              For web and mobile, I enjoy <b>TypeScript, React, React Native, Redux, Vue, Swift</b> and <b>Node</b>.
            </t.LargeP>
            <t.LargeP align="center" max70>
              <b> Bash Scripting, Docker, AWS, Github Actions, and Azure </b> for DevOps/CI-CD.
            </t.LargeP>
            <AnchorLink href="#section" offset="100">
              <HireMe large book grey>
                View My Work
              </HireMe>
            </AnchorLink>
          </AboveFold>
          <Content id="section">
            <t.H2 primary align="center" bold className="portfolio">
              Projects
            </t.H2>
          </Content>
          <Block id="Thesis">
            <BlockContent>
              <DivWrapper>
                <ItemVid>
                  <img src={thesis} alt="Geovisualization Timeline" />
                </ItemVid>
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Weather and Mortality</t.H2>
                <t.P>
                  Statistical analysis and visaulization of extreme weather patterns and its correlation to human
                  mortality in sub-saharan Africa
                </t.P>
                <li>Python (Geemap, Pandas, Seaborn)</li>
                <li>Google Earth Engine</li>
                <li>R</li>
                <li>QGIS</li>
                <t.P>
                  <i>
                    This an active Master's thesis project for my degree in Epidemology at the University of Basel.
                    Contact me for code details.
                  </i>
                </t.P>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="EY">
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Ernst & Young</t.H2>
                <t.P>Tax application for the Nordic market.</t.P>
                <li>Vue</li>
                <li>Node</li>
                <li>Python</li>
                <li>Kubernetes</li>
                <li>Microsoft Azure</li>
                <t.P>
                  <i>This was an internal EY project..</i>
                </t.P>
              </DivWrapper>
              <DivWrapper>
                <ItemVid>
                  <img src={EY} alt="EY Logo" />
                </ItemVid>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="Myyntimaatio">
            <BlockContent>
              <DivWrapper>
                <ItemVid>
                  <img src={Myynti} alt="Myyntimaatio" />
                </ItemVid>
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Myyntimaatio</t.H2>
                <t.P>A React Native application for an external client. Included the following technologies:</t.P>
                <li>React Native</li>
                <li>TypeScript</li>
                <li>Redux Toolkit</li>
                <li>Expo</li>
                <li>React Native Elements</li>
                <li>Victory Charts</li>
                <li>Google Analytics</li>
                <li>AWS Amplify, Cognito, AppSync, Lambda Functions</li>
                <li>Github Actions</li>
                <t.P>
                  As the sole full-stack developer, I was fully response for the architecture and implementation of the
                  frontend and backend. I developed a web admin site using React, TypeScript, Material-UI, Formik, and
                  Redux.
                </t.P>
                <t.P>
                  <i>This was a consulting project. Contact me for code details.</i>
                </t.P>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="Yard and Valpas">
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Yard & Valpas</t.H2>
                <t.P>
                  Two consulting projects - a mobile application for travellers and another mobile application for B2B
                  recruitment.
                </t.P>
                <li>React Native</li>
                <li>Expo</li>
                <li>Redux-Saga</li>
                <li>NativeBase</li>
                <li>AWS Amplify</li>
                <li>Contentful</li>
                <t.P>
                  <i>This was a consulting project. Contact me for code details.</i>
                </t.P>
              </DivWrapper>
              <DivWrapper>
                <ItemVid>
                  <img src={Yard} alt="Yard Logo" />
                </ItemVid>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="sms-backend">
            <BlockContent>
              <DivWrapper>
                <ItemVid>
                  <img src={Swagger} alt="SMS-Backend Swagger" />
                </ItemVid>
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Student Management System API</t.H2>
                <t.P>
                  A REST API for a student management system for Integrify. Includes the following technologies:
                </t.P>
                <li>NestJS</li>
                <li>TypeScript</li>
                <li>Node</li>
                <li>TypeORM</li>
                <li>PostGreSQL</li>
                <li>Swagger</li>
                <li>Jest e2e Testing</li>
                <li>Docker</li>
                <li>Github hooks for CI/CD</li>
                <t.P>
                  I worked as full-time <b>tech-lead</b> and occasional scrum master for this API, which included a team
                  of four backend developers. We also worked closely with two frontend teams.
                </t.P>
                <t.P>
                  Highlights of my accomplishments include setting up our CI/CD workflow with Github hooks, creating a
                  cross-platform development environment with Docker, writing bash scripts for automated e2e testing
                  while pushing code upstream and dumping databases from the cloud, as well as working as lead reviewer
                  of pull requests, and contributing to nearly 50% of our code base via new feature, bug fixes, etc.
                </t.P>
                <t.P>
                  <i>This was an internal company project. Contact me for code details.</i>
                </t.P>
              </DivWrapper>
            </BlockContent>
          </Block>

          <Block id="real-cost-app">
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>The Real Cost App </t.H2>
                <t.P>A fullstack single page app. Includes the following technologies:</t.P>
                <li>Semantic UI</li>
                <li>React Hooks</li>
                <li>React-Router</li>
                <li>Victory Data Charting</li>
                <li>JSON Web Tokens</li>
                <li>Node</li>
                <li>Express</li>
                <li>RESTful API</li>
                <li>MongoDB</li>
                <li>Jest Unit Testing</li>
                <li>Cypress E2E Testing</li>
                <t.P>
                  Users can create an account, log in, and track the frequency and per cost use of memberships/expenses.
                  It's already encouraged me to use the gym more!
                </t.P>
                <t.P>
                  Independently designed and built as part of the 18 credit Full Stack Open course + project at the
                  University of Helsinki.
                </t.P>
                <t.P></t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  target="_blank"
                  href="https://github.com/daniel-stafford/The-Real-Cost-App"
                >
                  GitHub
                </LinkButton>
              </DivWrapper>
              <DivWrapper>
                <ItemVid>
                  <ReactPlayer width="100%" url="https://vimeo.com/375509504" />
                </ItemVid>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="oh-my-scripts">
            <BlockContent>
              <DivWrapper>
                <ItemVid>
                  <img src={CountCommits} alt="Count Commits Demo" />
                </ItemVid>
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Oh My Scripts! </t.H2>
                <t.P>A collection o Shell and AppleScripts for a speedy workflow:</t.P>
                <li>Count Github commits</li>
                <li>Moving production and dev dependencies</li>
                <li>Connecting bluetooth headphones</li>
                <li>Checking a Git push for console.logs, latests pulls and showing diffs</li>
                <li>Connecting to wifi</li>
                <li>Opening up multiple panes in iTerm</li>
                <t.P></t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  target="_blank"
                  href="https://github.com/daniel-stafford/Oh-My-Scripts"
                >
                  GitHub
                </LinkButton>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block id="aalto-lunch-finder">
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Aalto University Lunch Finder</t.H2>
                <t.P>
                  A command line interface application built with Scala where users can view lunch menu options at
                  student restaurants in Aalto University. Includes the following technologies/paradigms:
                </t.P>
                <li>Unified Modeling Language (UML) planning</li>
                <li>Object Oriented Programming (OOP) structure</li>
                <li>JSOUP and Scala XML for parsing data from RSS feeds</li>
                <t.P />
                <t.P>Developed independently while taking Aalto University's Programming Studio 2 course.</t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  target="_blank"
                  href="https://github.com/daniel-stafford/Aalto-Lunch-Finder"
                >
                  GitHub
                </LinkButton>
              </DivWrapper>
              <DivWrapper>
                <ReactPlayer width="100%" url="https://vimeo.com/375516728" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <WorkWithMe>
            <t.H1 green>Get in touch!</t.H1>
            <HireMe onClick={this.openContactPopup} book>
              Contact me
            </HireMe>
          </WorkWithMe>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
    yard: file(relativePath: { eq: "yard.png" }) {
      ...fluidImage
    }
  }
`;
